<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">SERVICE</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">Service</span>
            </div>

        </div>
        <div class="scontainer">
            <div class="section">
                <div class="sectionin row justify-content-center">
                    <div class="proimg">
                        <img :src="require('@/assets/img/sub/service_main02.png')">
                    </div>
                    <div class="protxt">
                        <div class="tit">
                            사용자 중심의 UI Safety/Perfect/Big Data 기반 컨텐츠 제공
                        </div>
                        <div class="txt">
                            일상적인 프린터 임대외 다양한 상품의 임대 및 유지 보수에 이르기까지 TN-BOX MPS(Managed Printer Service System)는
                            위치에 관계없이 임대 관리, 추적, 모니터링, 보안, A/S 작업관리, 매입, 매출, CMS 등 임대 사업 전반에 걸쳐 지원 서비스를 제공합니다.
                        </div>
                    </div>
                </div>
            </div>

            <div class="section bggrey">
                <div class="sectionin">
                    <div class="stit">MPS 서비스</div>
                    <div class="probox">
                        <div class="img_wrap text-center">
                            <img :src="require('@/assets/img/sub/service_mornitoring.png')"
                                 class="img-fluid"/>
                        </div>
                        <div class="text-lg-left txtbox">
                            <div class="cttit custom-bar _left text-color-dark">Connected IOT 와 실시간 통합 모니터링</div>
                            <p>1. Tnbox 자체 제작된 단말 또는 PC Agent 로 프린터의 상태와 사용량의 실시간 모니터링 가능합니다.</p>
                            <p class="mb-5">2. 프린터에 따라서 특정 기능 제어 및 활성화/비활성화 제어가 가능합니다.</p>
                        </div>
                    </div>
                    <div class="probox m_probox">
                        <div class="text-lg-left txtbox txttbox_second">
                            <div class="cttit custom-bar _right text-color-dark">A/S Workforce Management</div>
                            <p>1. 작업자의 작업 내역을 직관적인 최단거리 위치정보 맵을 통해서 플래닝 할 수 있습니다. </p>
                            <p>2. 작업자의 실시간 위치 현황을 파악 할 수 있습니다. </p>
                        </div>
                        <div class="img_wrap text-center">
                            <img :src="require('@/assets/img/sub/service_quick_as.png')" class="img-fluid"/>
                        </div>
                    </div>
                    <div class="probox">
                        <div class="img_wrap">
                            <img :src="require('@/assets/img/sub/pro_img02.png')" class="img-fluid"/>
                        </div>
                        <div class="text-lg-left txtbox">
                            <div class="cttit custom-bar _left text-color-dark">설정 기반의 매출, 입출금, 세금 계산서 자동화 기술</div>
                            <p>수백, 수천 임대 거래처의 설정만으로 매출, 입출금, 세금 계산서 업무를 자동화하여 인적 실수를 최소화하고 비용을 절약할 수 있습니다.   </p>
                        </div>
                    </div>
                    <div class="probox m_probox">
                        <div class="text-lg-left txtbox txttbox_second">
                            <div class="cttit custom-bar _right text-color-dark">빅 데이터 기반의 보고서</div>
                            <p>1. 미수, 만기 도래 거래처, AS Top5, 임대 유형별, 추가 출력량 등 빅 데이터 기반의 보고서를 통해서 다양한 Insight를 제공 </p>
                            <p>2. 단순 ERP 시스템의 거래처 중복 입력, 분산데이타의 위험성에서 클라우드 기반의 통합된 데이타로 보다 안전하고 고객 정보의 보안을 강화할 수 있습니다. </p>
                        </div>
                        <div class="img_wrap text-center">
                            <img :src="require('@/assets/img/sub/service_report.png')" class="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">

                <div class="sectionin">

              <div class="stit">서비스 특장점</div>
                    <div class="iconarea">
                        <ul>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon01.svg')">
                                <div class="icontxt">모니터링</div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon02.svg')">
                                <div class="icontxt">보고서 작성</div>
                            </li>
                            <li class="mgrnone">
                                <img :src="require('@/assets/img/sub/p_icon03.svg')">
                                <div class="icontxt">맞춤형 솔루션 제공</div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon04.svg')">
                                <div class="icontxt">보안강화</div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon05.svg')">
                                <div class="icontxt">정확한 장치 정보 수집</div>
                            </li>
                            <li class="mgrnone">
                                <img :src="require('@/assets/img/sub/p_icon06.svg')">
                                <div class="icontxt">WorkFlow 최적화</div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon07.svg')">
                                <div class="icontxt">환경 친화적 솔루션</div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/sub/p_icon08.svg')">
                                <div class="icontxt">배포 관리(정기 업데이트)</div>
                            </li>
                            <li class="mgrnone">
                                <img :src="require('@/assets/img/sub/p_icon09.svg')" class="smimg">
                                <div class="icontxt">소모품 및 서비스 비용 절감</div>
                            </li>
                        </ul>
                    </div>
                    <div class="mobile_iconarea ">
                        <ul>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon01.svg')">
                                </div>
                                <div class="icontxt">모니터링</div>
                            </li>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon02.svg')">
                                </div>
                                <div class="icontxt">보고서 작성</div>
                            </li>
                            <li class="mgrnone">
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon03.svg')">
                                </div>
                                <div class="icontxt">맞춤형 솔루션 제공</div>
                            </li>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon04.svg')">
                                </div>
                                <div class="icontxt">보안강화</div>
                            </li>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon05.svg')">
                                </div>
                                <div class="icontxt">정확한 장치 정보 수집</div>
                            </li>
                            <li class="mgrnone">
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon06.svg')">
                                </div>
                                <div class="icontxt">WorkFlow 최적화</div>
                            </li>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon07.svg')">
                                </div>
                                <div class="icontxt">환경 친화적 솔루션</div>
                            </li>
                            <li>
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon08.svg')">
                                </div>
                                <div class="icontxt">배포 관리(정기 업데이트)</div>
                            </li>
                            <li class="mgrnone">
                                <div class="iconBox">
                                    <img :src="require('@/assets/img/sub/p_icon09.svg')">
                                </div>
                                <div class="icontxt">소모품 및 서비스 비용 절감</div>
                            </li>
                        </ul>
                    </div>
                </div>



            </div>
            <!--<section>
                <common-site-map></common-site-map>
            </section>-->
          <cost-table/>
        </div>
        <common-footer></common-footer>
    </div>
</template>

<style scoped>

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background: #f9f9f9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        /*position: absolute;
        top: 53px;
        z-index: 99;
        padding: 0;
        background-color: red;
        width: 100%;*/
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/

    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;


    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }


    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        padding: 100px 20px;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .scontainer .section .sectionin .proimg {
        float: left;
        width: 620px;
    }

    .scontainer .section .sectionin .proimg img {
        width: 100%
    }

    .scontainer .section .sectionin .protxt {
        float: left;
        width: 500px;
        padding-left: 80px;
    }

    .scontainer .section .sectionin .protxt .tit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 62px;
        font-weight: 700;
    }

    .scontainer .section .sectionin .protxt .txt {
        color: #000;
        line-height: 32px;
        letter-spacing: -0.9px;
        font-weight: 500;
        font-size: 1.8rem;
        padding-top: 28px;
    }


    .scontainer .section.bggrey {
        background-color: #FAFAFA;
    }

    .scontainer .section .sectionin .stit {
        text-align: center;
        font-size: 4.5rem;
        color: #000;
        font-weight: 900;
        letter-spacing: -1.25px;
        line-height: 40px;
        margin-bottom: 100px;
    }

    .scontainer .section .sectionin ul {
        overflow: hidden;
        padding-left: 0px;
    }

    .scontainer .section .sectionin ul li {
        float: left;
        width: 384px;
        height: 294px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 20px;
        margin-right: 24px;
        list-style: none;
        margin-bottom: 24px;
        text-align: center;
    }

    .scontainer .section .sectionin ul li.mgrnone {
        margin-right: 0px;
    }

    .scontainer .section .sectionin ul li img {
        width: 120px;
        height: 123px;
        margin-top: 55px;
    }

    .scontainer .section .sectionin ul li img.smimg {
        width: 74px;
    }

    .scontainer .section .sectionin ul li .icontxt {
        font-size: 3rem;
        line-height: 40px;
        letter-spacing: -1.68px;
        padding-top: 20px;
        color: #000;
        font-weight: 600;
    }

    .scontainer .section .sectionin .mobile_iconarea {
        display: none;
    }

    .scontainer .section .sectionin .probox {
        margin-bottom: 152px;
    }

    .scontainer .section .sectionin .txtbox {
        padding-top: 16px;
    }

    .scontainer .section .sectionin h2 {
        font-size: 4.5rem;
        color: #000;
        font-weight: 900;
        line-height: 62px;
        letter-spacing: -1.25px;
    }

    .scontainer .section .sectionin .cttit {
        font-size: 3.5rem;
        color: #000;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -1.25px;
        margin-bottom: 20px;
    }

    .scontainer .section .sectionin p {
        font-size: 1.8rem;
        line-height: 32px;
        letter-spacing: -1px;
        color: #000;
        margin-bottom: 0;
    }

    .img_wrap {
        width: 45%;
        /*height: 280px;*/
    }

    .img_wrap img {
        width: 100%;
    }

    .probox {
        display: flex;
        justify-content: space-between;
    }

    .txtbox {
        width: 50%;
        padding-left: 0px;
    }

    .txttbox_second {
        padding-left: 0;
        width: 50%;
        padding-right: 0px;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }

    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        #header{
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo{
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2)  img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem ;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */



        .sbreadcrumb .bdinner {
            padding-left: 20px;
        }

        .scontainer .section .sectionin {
            padding: 0 20px;
            width: 100%;
        }

        .scontainer .section .sectionin .proimg {
            width: 100%;
        }

        .scontainer .section .sectionin .proimg img {
            width: 100%
        }

        .scontainer .section .sectionin .protxt {
            width: 100%;
            padding: 0 20px;
            margin-top: 20px;
        }

        .scontainer .section .sectionin .protxt .tit {
            color: #000;
            font-size: 3rem;
            letter-spacing: -1.15px;
            line-height: 70px;
            font-weight: 700;
            padding-top: 20px;
        }

        .scontainer .section .sectionin ul {
           overflow: hidden;
           padding: 0 20px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .scontainer .section .sectionin ul li {
            float: left;
            width: 30%;
            height: 227px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 20px;
            margin-right: 24px;
            list-style: none;
            margin-bottom: 24px;
            text-align: center;
        }

        .scontainer .section .sectionin ul li.mgrnone {
            margin-right: 0px;
        }

        .scontainer .section .sectionin ul li img {
            width: 100px;
            height: 100px;
            margin-top: 32px;
        }

        .scontainer .section .sectionin ul li img.smimg {
            width: 74px;
        }

        .scontainer .section .sectionin ul li .icontxt {
            font-size: 1.8rem;
            line-height: 40px;
            letter-spacing: -1.68px;
            padding-top: 20px;
            color: #000;
            font-weight: 600;
        }

        .scontainer .section .sectionin .mobile_iconarea {
            display: none;
        }

        .scontainer .section .sectionin .probox {
            margin-bottom: 80px;
            padding: 0 20px;
        }

        .scontainer .section .sectionin .txtbox {
            padding-top: 16px;
        }

        .scontainer .section .sectionin .cttit {
            font-size: 3rem;
            color: #000;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: -1.25px;
            margin-bottom: 10px;
        }

        .scontainer .section .sectionin p {
            font-size: 1.4rem;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;

        }

        .subtop .subtopin {
            width: 1200px;
            margin: 0 auto;
        }

        .subtop .subtopin img {
            width: 418px;
            display: inline-block;
            margin-left: 6%;
        }

        .txtbox {
            width: 50%;
            padding-left: 0;
            padding-top: 16px;
        }

        .txttbox_second {
            width: 50%;
            padding: 0;
        }

        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }


        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }

    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {
        /*grid*/

        /*모바일 헤더 메뉴 start */

        #header{
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo{
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .col-lg-6 {
            padding-right: 0px;
            padding-left: 0px;
        }


        .scontainer .section {
            padding: 48px 20px;
        }

        .subtop {
            width: 100%;
            height: 97px;
            border-bottom: 1px solid #0F70B7;
        }

        .subtop .subtopin {
            width: 100% !important;
            margin: 0 10% !important;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 0px;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }

        .scontainer .section {
            padding: 48px 20px 10px 20px;
        }

        .scontainer .section .sectionin {
            width: 100%;
            overflow: hidden;
        }

        .scontainer .section .sectionin .proimg {
            float: initial;
            width: 80%;
            text-align: center;
        }

        .scontainer .section .sectionin .proimg img {
            width: 100%
        }

        .scontainer .section .sectionin .protxt {
            float: initial;
            width: 100%;
            padding: 20px 0px 0px 0px;
        }

        .scontainer .section .sectionin .protxt .tit {
            color: #000;
            font-size: 2rem;
            letter-spacing: -1px;
            line-height: 30px;
            font-weight: 900;
            padding-top: 20px;
        }

        .scontainer .section .sectionin .protxt .txt {
            color: #000;
            line-height: 24px;
            letter-spacing: -0.7px;
            font-weight: 600;
            font-size: 1.4rem;
            padding-top: 12px;
        }

        .scontainer .section .sectionin .stit {
            text-align: center;
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            letter-spacing: -1px;
            line-height: 40px;
            margin-bottom: 32px;
        }

        .scontainer .section .sectionin .iconarea {
            display: none;
        }

        .scontainer .section .sectionin .mobile_iconarea {
            display: block;
        }

        .scontainer .section .sectionin .mobile_iconarea ul {
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .scontainer .section .sectionin .mobile_iconarea ul li {
            display: inline-table;
            width: 30%;
            border: 0px;
            height: 124px;
            margin-right: 0px;
        }

        .scontainer .section .sectionin .mobile_iconarea ul li .iconBox {
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 10px;
            display: block;
            /*margin-right: 12px;*/
            height: 74px;
            line-height: 74px;
        }

        .scontainer .section .sectionin .mobile_iconarea ul li .iconBox img {
            width: 45px;
            height: 36px;
            margin-top: 0px;
        }

        .scontainer .section .sectionin .mobile_iconarea ul li .icontxt {
            font-size: 1.4rem;
            line-height: 20px;
            display: block;
            /*margin-right: 12px;*/
            padding-top: 10px;
        }

        .scontainer .section .sectionin .probox {
            margin-bottom: 60px;
        }

        .scontainer .section .sectionin .txtbox {
            padding-top: 23px;
        }

        .scontainer .section .sectionin h2 {
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            line-height: 30px;
            letter-spacing: -1px;
            margin-bottom: 6px;
        }

        .scontainer .section .sectionin .cttit {
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: -1.25px;
            margin-bottom: 10px;
        }

        .scontainer .section .sectionin p {
            font-size: 1.4rem;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
        }

        .probox {
            display: block;
        }

        .m_probox {
            display: flex !important;
            flex-direction: column-reverse;
        }

        .m_probox p {
            margin: 0;
        }

        .img_wrap {
            width: 100%;
            height: auto;
        }

        .txtbox {
            width: 100%;
            padding-left: 0px;
        }

        .txttbox_second {
            width: 100%;
            padding-right: 0;
        }

        .five_content {
            display: none;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }


    }

    /*margin*/

    .mgb208 {
        margin-bottom: 208px;
    }

</style>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";
    import CostTable from "@/components/CostTable";

    export default {
        name: "Products",
        components: {CostTable, CommonFooter, CommonHeader}
    }

</script>
