import { render, staticRenderFns } from "./CostTable.vue?vue&type=template&id=2801acf8&scoped=true&"
import script from "./CostTable.vue?vue&type=script&lang=js&"
export * from "./CostTable.vue?vue&type=script&lang=js&"
import style0 from "./CostTable.vue?vue&type=style&index=0&id=2801acf8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2801acf8",
  null
  
)

export default component.exports