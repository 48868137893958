<template>
    <div class="cost-table">
        <div>
            <div class="top_banner flex justify-content-center">
                <div>
                    <!--<img src="@/assets/image/mainLogo2.png">-->
                </div>
                <div class="stit">TN-BOX MPS 플랜 및 가격</div>
            </div>
            <div class="section_03">
                <table>
                    <tr>
                        <th rowspan="2">서비스내용</th>
                        <!--                            <th rowspan="2">서비스 가격<br/>(50% 할인 이벤트)</th>-->
                        <th>MPS</th>
                        <!--<th>Tnbox-Start</th>
                        <th>Tnbox-Lite</th>
                        <th>Tnbox-Basic</th>
                        <th>Tnbox-Standard</th>
                        <th>Tnbox-Pro</th>
                        <th>Tnbox-Premium</th>-->
                    </tr>
                    <tr class="discount_price">
                        <th>
                            <div>￦1,320,000 (v.a.t 포함)</div>
                            <br/>12개월
                        </th>
                        <!--<th>
                            <del>10만원/월</del>
                            <br/>5만원/월
                        </th>
                        <th>
                            <del>15만원/월</del>
                            <br/>7만5천원/월
                        </th>
                        <th>
                            <del>25만원/월</del>
                            <br/>12만5천원/월
                        </th>
                        <th>
                            <del>35만원/월</del>
                            <br/>17만5천원/월
                        </th>
                        <th>
                            <del>50만원/월</del>
                            <br/>25만원/월
                        </th>
                        <th>
                            별도 문의
                        </th>-->
                    </tr>
                    <tr>
                        <td class="preview">프린터 모니터링
                            <service-price-mornitoring></service-price-mornitoring>
                        </td>
                        <!--                            <td rowspan="7" class="border_point">프린터 당 500원</td>-->
                        <td class="font-weight-bold"></td>
                        <!--<td class="font-weight-bold">200 계약</td>
                        <td class="font-weight-bold">300 계약</td>
                        <td class="font-weight-bold">500 계약</td>
                        <td class="font-weight-bold">700 계약</td>
                        <td class="font-weight-bold">1000 계약</td>
                        <td class="font-weight-bold">별도 문의</td>-->
                    </tr>
                    <tr>
                        <td>장치 관리</td>
                        <td>○</td>
                       <!-- <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>프린터 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>프린터 사용량 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>사용량 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>소모품 상태 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td class="border_point">AS 작업 자동 생성 관리</td>
                        <td class="border_point">○</td>
                        <!--<td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>-->
                    </tr>
                    <tr>
                        <td class="preview">AS 작업 관리</td>
                        <!--                            <td rowspan="4" class="border_point">무료</td>-->
                        <td></td>
                        <!--<td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>-->
                    </tr>
                    <tr>
                        <td>AS 작업 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>작업자별 AS 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td class="border_point">작업 위치 추적</td>
                        <td class="border_point">○</td>
                        <!--<td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>-->
                    </tr>
                    <tr>
                        <td class="preview">임대 사업 지원 서비스</td>
                        <td></td>
                        <!--<td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>-->
                    </tr>
                    <tr>
                        <td>거래처 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>임대계약서 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>프린터계약서 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>매출 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>CMS 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>세금계산서 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>내용증명서 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td>알림 관리</td>
                        <td>○</td>
                        <!--<td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>-->
                    </tr>
                    <tr>
                        <td class="border_point">각종 Dashboard 관리</td>
                        <td class="border_point">○</td>
                        <!--<td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>
                        <td class="border_point">○</td>-->
                    </tr>
                    <!--<tr>
                        <td colspan="8" class="border_point font-weight-bold">모바일 앱(안드로이드 / iOS)지원
                            <service-price-mobile-app></service-price-mobile-app>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8" class="border_point font-weight-bold">거래처 My Printer 앱 (준비중)</td>
                        &lt;!&ndash;  <td class="border_point preview">거래처 My Printer 앱
                              <service-price-account-app></service-price-account-app>
                          </td>
                          <td class="border_point">10거래처 까지 무료</td>
                          <td class="border_point">20거래처 까지 무료</td>
                          <td class="border_point">30거래처 까지 무료</td>
                          <td class="border_point">50거래처 까지 무료</td>
                          <td class="border_point">100거래처 까지 무료</td>
                          <td class="border_point">별도 문의</td>&ndash;&gt;
                    </tr>
                    <tr>
                        <td colspan="8" class="border_point font-weight-bold">ERP 서비스 (준비중)</td>
                    </tr>-->
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CostTable"
    }
</script>

<style scoped>
    .cost-table {
        background-color: #fafafa;
        padding: 112px 0;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        width: 100%;
    }

    th {
        border: 1px solid #e1e1e1;
        padding: 10px;
        background: #0F70B7;
        font-size: 16px;
        text-align: center;
        line-height: 0.9;
        color: #fff;
    }

    td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        font-size: 14px;
        color: #333;
    }

    .border_point {
        border-bottom: 1px solid #888;
    }

    .preview {
        font-weight: bold;
    }

    .preview button {
        vertical-align: bottom;
    }

    >>> .v-btn:not(.v-btn--outlined).primary, .v-btn:not(.v-btn--outlined).secondary, .v-btn:not(.v-btn--outlined).accent, .v-btn:not(.v-btn--outlined).success, .v-btn:not(.v-btn--outlined).error, .v-btn:not(.v-btn--outlined).warning, .v-btn:not(.v-btn--outlined).info {
        color: #000 !important;
    }

    >>> .v-application .primary {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        height: 21px;
        min-width: 8px;
        max-width: 24px;
        padding: 0 16px;
    }

    /*공통 끝*/

    .top_banner {
        width: 100%;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        display: flex;
    }

    .top_banner > div:first-child {
        width: 22px;
        margin-right: 16px;
    }

    .top_banner > div:first-child > img {
        width: 100%;
    }

    .top_banner div:nth-child(2) {
        font-size: 4.5rem;
        color: #2c2c2c;
    }

    .section_01 {
        padding: 54px 160px;
        background: white;
    }

    .event {
        width: 100%;
        height: 308px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        padding: 47px 62px 0px 62px;
        position: relative;
    }

    .event > img {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .event > div > div:first-child {
        font-size: 26px;
        color: #222;
    }

    .event > div > div:nth-child(2) {
        width: 69px;
        height: 31px;
        background: #EE48C1;
        color: white;
        font-size: 16px;
        border-radius: 4px;
        text-align: center;
        padding-top: 4px;
        margin-left: 20px;
    }

    .event_text {
        margin-top: 20px;
    }

    .event_text div:first-child div {
        font-size: 21px;
        color: #222;
        margin-bottom: 10px;
    }


    .promotion {
        width: 100%;
        height: 258px;
        background: #f5f5f5;
        margin-bottom: 54px;
        border: 1px solid #e1e1e1;
        padding: 47px 62px 0px 62px;
        position: relative;
    }

    .promotion > img {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .promotion > div > div:first-child {
        font-size: 24px;
        color: #222;
    }

    .promotion > div > div:nth-child(2) {
        width: 69px;
        height: 31px;
        background: #EE48C1;
        color: white;
        font-size: 16px;
        border-radius: 4px;
        text-align: center;
        padding-top: 4px;
        margin-left: 20px;
    }

    .section_02 {
        padding: 0px 160px;
        background: white;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .price_wrap {
        width: 48.5%;
        height: 680px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        margin-bottom: 54px;
        padding: 40px 27px;
    }

    .price_title {
        font-size: 24px;
        margin-bottom: 17px;
    }

    .price_title_wrap {
        display: flex;
    }

    .price_title_wrap div:nth-child(2) {
        width: 69px;
        height: 30px;
        background: #319fff;
        font-size: 16px;
        color: white;
        text-align: center;
        border-radius: 4px;
        padding-top: 4px;
        margin-left: 20px;
    }

    .price {
        align-items: baseline;
    }

    .price span:first-child {
        font-size: 28px;
        font-weight: bold;
    }

    .price span:nth-child(2) {
        font-size: 16px;
    }

    .service_list {
        margin-top: 60px;
    }

    .service_list ul {
        width: 33%;
    }

    .service_list ul li {
        font-size: 16px;
    }

    .bottom_text {
        font-size: 18px;
        font-width: bold;
        margin-top: 74px;
    }

    .section_03 {
        /*padding: 0px 160px 54px 160px;*/
        width: 50%;
        margin: 0 auto;
        background: white;
    }

    .discount_price th del {
        font-weight: normal;
        font-size: 15px;
    }

    .section_07 {
        padding: 0 30px;
        height: 110px;
        background: #cfcfcf;
        justify-content: space-between;
        align-items: center;
    }

    .section_07_info div {
        font-size: 15px;
        color: #333;
    }

    .stit {
        text-align: center;
        font-size: 4.5rem;
        color: #000;
        font-weight: 900;
        letter-spacing: -1.25px;
        line-height: 40px;
        margin-bottom: 100px;
    }

</style>
