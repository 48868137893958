var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cost-table"},[_c('div',[_vm._m(0),_c('div',{staticClass:"section_03"},[_c('table',[_vm._m(1),_vm._m(2),_c('tr',[_c('td',{staticClass:"preview"},[_vm._v("프린터 모니터링 "),_c('service-price-mornitoring')],1),_c('td',{staticClass:"font-weight-bold"})]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_banner flex justify-content-center"},[_c('div'),_c('div',{staticClass:"stit"},[_vm._v("TN-BOX MPS 플랜 및 가격")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("서비스내용")]),_c('th',[_vm._v("MPS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"discount_price"},[_c('th',[_c('div',[_vm._v("￦1,320,000 (v.a.t 포함)")]),_c('br'),_vm._v("12개월 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("장치 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("프린터 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("프린터 사용량 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("사용량 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("소모품 상태 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"border_point"},[_vm._v("AS 작업 자동 생성 관리")]),_c('td',{staticClass:"border_point"},[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"preview"},[_vm._v("AS 작업 관리")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("AS 작업 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("작업자별 AS 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"border_point"},[_vm._v("작업 위치 추적")]),_c('td',{staticClass:"border_point"},[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"preview"},[_vm._v("임대 사업 지원 서비스")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("거래처 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("임대계약서 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("프린터계약서 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("매출 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("CMS 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("세금계산서 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("내용증명서 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("알림 관리")]),_c('td',[_vm._v("○")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"border_point"},[_vm._v("각종 Dashboard 관리")]),_c('td',{staticClass:"border_point"},[_vm._v("○")])])
}]

export { render, staticRenderFns }